<template>
  <b-modal
    id="MessagesSendFormSearchUsers"
    no-close-on-backdrop
    hide-footer
    centered
    title="회원 검색"
    modal-class="custom-modal-size"
    @shown="modalOpen"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        받는 회원
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="modalClose"
        />
      </div>
    </template>
    <b-card no-body>
      <b-form @submit.prevent>
        <b-row>
          <b-col
            cols="12"
            lg="3"
            md="3"
          >
            <b-form-group
              label="사이트"
              label-cols-sm="3"
              label-align-sm="right"
              label-align="left"
              label-for="searchSite"
            >
              <b-form-select
                id="site-select"
                v-model="siteSelected"
                :options="authSite"
                class="font-small-3"
                size="sm"
                style="color:rgb(229,166,48)"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="3"
          >
            <b-form-group
              label="권한"
              label-cols-sm="3"
              label-align-sm="right"
              label-align="left"
              label-for="searchRole"
            >
              <b-form-select
                v-if="options.selectedRole"
                id="searchRole"
                v-model="search.role"
                :options="options.selectedRole"
                class="font-small-3"
                size="sm"
                style="color:rgb(229,166,48);"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="3"
          >
            <b-form-group
              label="랭크"
              label-cols-sm="3"
              label-align-sm="right"
              label-align="left"
              label-for="searchRank"
            >
              <b-input-group class="input-group-merge">
                <b-form-select
                  v-if="optionsRank"
                  id="searchRank"
                  v-model="search.rank"
                  :options="optionsRank"
                  class="font-small-3"
                  size="sm"
                  style="color:rgb(229,166,48); "
                >
                  <template
                    v-if="true"
                    #first
                  >
                    <b-form-select-option :value="null">
                      전체
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="3"
            md="3"
          >
            <b-form-group
              label="상태"
              label-cols-sm="3"
              label-align-sm="right"
              label-align="left"
              label-for="searchStatus"
            >
              <b-form-select
                v-if="options.selectedStatus"
                id="searchStatus"
                v-model="search.status"
                :options="options.selectedStatus"
                class="font-small-3"
                size="sm"
                style="color:rgb(229,166,48); "
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <hr
      v-if="false"
      class="pb-1"
    >
    <b-card no-body>
      <div>
        <div class="">
          <b-row class="justify-content-between">
            <b-col
              cols="3"
              md="3"
              style="margin-left: 2.2rem"
            >
              <b-form-group
                class="font-small-3"
                label="Show"
                label-for="betHistory-perPage"
                label-cols-lg="2"
                label-cols-md="2"
                label-align-md="left"
              >
                <b-form-select
                  id="betHistory-perPage"
                  v-model="perPage"
                  class="half-width"
                  size="sm"
                  :options="pageOptions"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="검색 결과 내 검색..."
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-row>
            <b-col cols="12">
              <b-table
                ref="receiversTable"
                responsive
                show-empty
                hover
                small
                empty-text="No matching records found"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="filteredItems"
                :fields="receiversFields"
                style="text-align: center; font-size: small"
                :sticky-header="stickyHeader"
                :no-border-collapse="noCollapse"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :current-page="currentPage"
                @filtered="onFiltered"
                @row-clicked="onRowClicked"
              >
                <template #head(rowSelected)>
                  <b-form-checkbox
                    v-model="isAllSelected"
                    @change="toggleAllSelected"
                  />
                </template>
                <template #cell(rowSelected)="data">
                  <b-form-checkbox
                    v-model="data.item.selected"
                  />
                </template>
                <template #cell(index)="data">
                  <div style="min-width: 2pxx">
                    {{ (perPage * (currentPage - 1)) + data.index + 1 }}
                  </div>
                </template>
                <template #cell(role)="data">
                  <b-media class="align-content-center">
                    <template>
                      <b-avatar
                        size="22"
                        :src="data.item.role"
                        :variant="`${resolveUserRoleVariant(data.item.role)}`"
                      />
                    </template>
                  </b-media>
                </template>
                <template #cell(status)="data">
                  <b-badge
                    pill
                    :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                    class="text-capitalize"
                  >
                    {{ convertUserStatus(data.item.status) }}
                  </b-badge>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
        <div class="mt-1">
          <b-row>
            <b-col
              cols="12"
              sm="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
            <b-col
              cols="12"
              md="6"
            />
            <b-col
              cols="12"
              md="6"
            >
              <b-button
                ref="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                size="sm"
                class="float-right"
                @click="btnAdd"
              >
                추가
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
    <div>
    </div>
  </b-modal>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BTable, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormGroup, BFormInput, BForm, BFormCheckbox, BFormSelect, BFormSelectOption, BModal, BMedia, BAvatar, BBadge, BPagination
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import { FETCH_SET_RANK } from '@/store/settings/action'
import { FETCH_MESSAGES_SEND_FORM_SEARCH_USERS } from '@/store/messages/action'

import { createNamespacedHelpers } from 'vuex'

const settingsStore = createNamespacedHelpers('settingsStore')
const messagesStore = createNamespacedHelpers('messagesStore')

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormSelect,
    BFormSelectOption,
    // b-form-select-option
    BModal,
    BMedia,
    BAvatar,
    BBadge,
    BPagination,
  },
  directives: {
    Ripple,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    paramSite: {
      type: String,
      required: false,
      default: '',
    },
    selectedReceivers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: this.paramSite,
      perPage: 10,
      visibleRows: [],
      pageOptions: [10, 30, 50],
      currentPage: 1,
      totalRows: 0,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      // Search Condition
      options: {
        selectedRole: [
          { value: null, text: '전체', selected: true },
          { value: 'admin', text: '어드민' },
          { value: 'branch', text: '총판' },
          { value: 'user', text: '유저(회원)' },
        ],
        selectedStatus: [
          { value: null, text: '전체', selected: true },
          { value: 'active', text: '정상회원' },
          { value: 'sleep', text: '휴먼회원' },
          { value: 'block', text: '차단회원' },
        ],
      },
      searchFilter: '',
      search: {
        site: this.selectedSite,
        role: null,
        rank: null,
        status: null,
        keyword: '',
      },

      // Table Options
      stickyHeader: true, // 헤더고정
      noCollapse: true, // 헤더고정
      receiversFields: [
        { key: 'rowSelected', label: '', thClass: 'w2' },
        { key: 'index', label: 'No', thClass: 'w45' },
        { key: 'site', label: '사이트', thClass: 'w120' },
        { key: 'role', label: 'role', thClass: 'w60' },
        { key: 'userid', label: '아이디', thClass: 'w120' },
        { key: 'nickname', label: '닉네임', thClass: 'w120' },
        { key: 'recommendNickname', label: '상위', thClass: 'w120' },
        // { key: 'rank', label: '랭크', thClass: 'w120' },
        { key: 'status', label: '상태', thClass: 'w60' },
      ],

      // Table Selected
      isAllSelected: false,
      selectedItems: [],
    }
  },
  computed: {
    ...settingsStore.mapGetters({
      fetchSetRank: 'fetchSetRank',
    }),
    ...messagesStore.mapGetters({
      fetchMessagesSendFormSearchUsers: 'fetchMessagesSendFormSearchUsers',
    }),
    // SearchCondition
    // optionsSite() {
    //   const authSite = localStorage.getItem('authSite').split(',')
    //   return authSite.map(site => ({ text: site, value: site }))
    // },
    optionsRank() {
      const formattedOptions = this.fetchSetRank.map(rank => ({
        text: `${rank.rankOrder} :: ${rank.rankName}`,
        value: rank.rankOrder,
      })).reverse()
      return formattedOptions
    },

    // Table Search Items
    filteredItems() {
      let filteredData = this.fetchMessagesSendFormSearchUsers

      if (this.search.role) { // 권한Filter
        // filteredData = filteredData.filter(item => this.search.role.includes(item.role))
        filteredData = filteredData.filter(item => {
          if (this.search.role === 'admin') {
            // role이 admin일 경우 site가 'admin'인 데이터 필터링
            return item.site === 'admin'
          } else if (this.search.role === 'branch') {
            // role이 branch일 경우 site가 selectedSite와 동일한 데이터 필터링
            return item.role === this.search.role
          } else {
            // 기타 경우 (예: user 등) role만 필터링
            return this.search.role.includes(item.role)
          }
        })
      }
      if (this.search.rank !== undefined && this.search.rank !== null) { // 랭크Filter
        console.log(this.search.rank)
        filteredData = filteredData.filter(item => this.search.rank === item.rank) // 숫자
      }
      if (this.search.status) { // 상태Filter
        filteredData = filteredData.filter(item => this.search.status.includes(item.status))
      }
      if (this.searchFilter) { // 검색Filter
        filteredData = filteredData.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      }
      return filteredData
    },
    paginatedItems() {
      const totalFilteredItems = this.filteredItems.length
      const start = totalFilteredItems - (this.currentPage * this.perPage)
      const end = start + this.perPage

      const paginatedItems = this.filteredItems.slice(Math.max(0, start), end).reverse()

      this.totalRows = totalFilteredItems
      this.pageFrom = (this.currentPage - 1) * this.perPage + 1 // 현재 페이지의 첫 번째 항목
      this.pageTo = Math.min(this.totalRows, this.currentPage * this.perPage) // 현재 페이지의 마지막 항목 또는 전체 항목 수 중 작은 값
      this.pageOf = this.totalRows

      return paginatedItems
    },
  },
  watch: {
    siteSelected: {
      handler(event) {
        this.fetchData()
        localStorage.setItem('authSiteSelected', event)
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
        // 부모에게 이벤트 전달
        console.log('site-changed @@@@@')
        this.$emit('changed', event) // 부모 컴포넌트에 'site-changed' 이벤트를 전달하며 'event' 값을 전달
      },
      immediate: false,
      deep: false,
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    ...settingsStore.mapActions({
      $fetchSetRank: FETCH_SET_RANK,
    }),
    ...messagesStore.mapActions({
      $fetchMessagesSendFormSearchUsers: FETCH_MESSAGES_SEND_FORM_SEARCH_USERS,
    }),
    async fetchData() {
      await this.$fetchMessagesSendFormSearchUsers({
        site: this.siteSelected,
        userid: this.userData.userid,
        role: this.userData.role,
      })
      this.totalRows = this.fetchMessagesSendFormSearchUsers.length
      await this.$fetchSetRank({
        site: this.siteSelected,
      })
    },
    btnAdd() {
      if (this.selectedItems.length === 0) {
        alert('최소 한개 이상 선택 해야합니다.')
        return
      }
      if (this.selectedItems.length > 0) {
        const data = this.selectedItems
          .map(item => ({
            userid: item.userid,
            nickname: item.nickname,
          }))
        this.$emit('update:selectedReceivers', data)
      }
      this.modalClose()
    },
    /* Table Row Click Event */
    toggleAllSelected() {
      this.filteredItems.forEach(item => {
        this.$set(item, 'selected', this.isAllSelected)

        const targetIdx = this.selectedItems.findIndex(data => data.userid === item.userid)
        if (targetIdx !== -1) {
          // 이미 배열에 있으면 제거
          this.selectedItems.splice(targetIdx, 1) // 해당 인덱스에서 1개의 요소 제거
        } else {
          // 배열에 없으면 추가
          this.selectedItems.push(item)
        }
      })
    },
    onRowClicked(row) {
      this.filteredItems.forEach(item => {
        if (item.userid === row.userid) {
          this.$set(item, 'selected', !row.selected)

          const targetIdx = this.selectedItems.findIndex(item => item.userid === row.userid)
          if (targetIdx !== -1) {
            // 이미 배열에 있으면 제거
            this.selectedItems.splice(targetIdx, 1) // 해당 인덱스에서 1개의 요소 제거
          } else {
            // 배열에 없으면 추가
            this.selectedItems.push(row)
          }
        }
      })
    },
    updateSelected() {
      if (this.filteredItems.length > 0) {
        this.isAllSelected = this.filteredItems.every(item => item.selected)
      } else {
        this.isAllSelected = false
      }
    },

    /* Table Variant */
    resolveUserRoleVariant(role) {
      return {
        user: 'secondary',
        branch: 'warning',
        admin: 'primary',
        godfather: 'danger',
      }[role] || 'secondary'
    },
    resolveUserStatusVariant(status) {
      return {
        apply: 'info',
        additional: 'info',
        await: 'info',
        active: 'success',
        sleep: 'primary',
        block: 'primary',
      }[status]
    },
    convertUserStatus(status) {
      return {
        apply: '1차가입',
        additional: '2차가입',
        await: '최종대기',
        active: '정상',
        sleep: '휴먼',
        block: '차단',
      }[status]
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    // Modal
    modalOpen() {
      console.log('showModal')
      this.siteSelected = this.paramSite
      this.fetchData()
    },
    modalClose() {
      console.log('closeModal')
      this.selectedItems = []
      this.isAllSelected = false
      this.$bvModal.hide('MessagesSendFormSearchUsers')
    },
  },
}
</script>

<style>
.custom-modal-size {
  /*max-width: 100rem; !* 너비를 80%로 설정 (필요에 따라 px 또는 %로 설정) *!*/
  /*max-height: 70%; !* 높이를 70%로 설정 *!*/
  /*width: 600px; !* 고정된 너비가 필요하면 설정 *!*/
  /*height: 500px; !* 고정된 높이가 필요하면 설정 *!*/
  overflow: auto; /* 내용이 넘칠 경우 스크롤 가능 */
}

.custom-modal-size .modal-dialog {
  /*margin: 0 auto; !* 수평 중앙 정렬 *!*/
  /*top: 50%; !* 수직 중앙 정렬 *!*/
  /*transform: translateY(-50%); !* 수직 중앙 정렬을 위한 트랜스폼 *!*/
}
</style>
